<template>
  <div>
    <a
      id="payment"
      href="javascript:void(0)"
      data-cb-type="checkout"
      v-bind:data-cb-plan-id="r"
    >
      Repayment
    </a>

    <br />

    <br />

    <a id="back" href="https://app.datagma.com/new-pricing"> Back</a>
  </div>
</template>
<script>
import $ from "jquery";
import {
  SIGN_UP_CODE
} from "../../../../store/constant/actions.type";
export default {
  data() {
    return {
      user_detail: {},
    };
  },
  methods: {
    decryptPlanID(encryptedText) {
      const decryptedText = this.$CryptoJS.AES.decrypt(
        encryptedText,
        "123drgkner"
      ).toString(this.CryptoJS.enc.Utf8);
      return decryptedText;
    },
  },

  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.$eventBus.$emit("callCreditMethod");
    let email = this.user_detail?.email;
    let Company = this.user_detail?.companyName;
    let createPaymentScript = document.createElement("script");
    createPaymentScript.onload = () => {
      window.Chargebee.init({});
      window.Chargebee.registerAgain();

      let createPaymentScript1 = document.createElement("script");
      createPaymentScript1.onload = () => {
        var chargebeeTrackFunc = function(fprom) {
          var tid = fprom.tid;
          var chargebeeInstance;
          let checkoutButtonElement;

          chargebeeInstance = window.Chargebee.getInstance();
          checkoutButtonElement = document.querySelectorAll(
            "[data-cb-type=checkout]"
          )[0];

          let customerDetails = {
            cf_tid: tid,
            email: email,
            billing_address: {
              email: email,
            },
          };

          if (Company && Company != null) {
            customerDetails.company = Company;
            customerDetails.billing_address.company = Company;
          }
          if (tid && chargebeeInstance) {
            var cart = chargebeeInstance.getCart();
            var product = chargebeeInstance.getProduct(checkoutButtonElement);

            cart.setCustomer(customerDetails);
            product.addCoupon(localStorage.getItem(SIGN_UP_CODE));
          } else if (tid) {
            document.addEventListener("DOMContentLoaded", function() {
              chargebeeTrackFunc(fprom);
            });
          } else if (chargebeeInstance) {
            var newcart = chargebeeInstance.getCart();
            var newproduct = chargebeeInstance.getProduct(
              checkoutButtonElement
            );
            newcart.setCustomer(customerDetails);
            newproduct.addCoupon(localStorage.getItem(SIGN_UP_CODE));
          }
        };
        window.fpr("onReady", chargebeeTrackFunc);
      };

      createPaymentScript1.setAttribute(
        "src",
        "https://cdn.firstpromoter.com/fpr.js"
      );
      document.head.appendChild(createPaymentScript1);
    };
    createPaymentScript.setAttribute(
      "src",
      "https://js.chargebee.com/v2/chargebee.js"
    );
    createPaymentScript.setAttribute("data-cb-site", "datagma");
    createPaymentScript.setAttribute("id", "datagma_1");
    document.head.appendChild(createPaymentScript);
    localStorage.setItem("2", false);
    this.$eventBus.$emit("callCreditMethod");
  },

  mounted() {
    $(document).ready(function() {
      var payment = document.getElementById("payment");
      setTimeout(function() {
        payment.click();
      }, 1000);
    });
  },

  computed: {
    r: function() {
      return this.decryptPlanID(this.$route.params.id);
    },
  },
  // watch: {
  //   getWatch() {
  //     let emailInput = document.getElementById("email");
  //     if (emailInput) {
  //       emailInput.setAttribute("disabled", true);
  //     }
  //   },
  // },
};
</script>
<style>
#payment {
  background: rgb(37 137 255);
  border-radius: 10px;
  padding: 10px 25px 10px 25px;
  color: rgb(255 255 255);
  font-size: 16px;
}

#back {
  background: rgb(37 137 255);
  border-radius: 10px;
  padding: 10px 25px 10px 25px;
  color: rgb(255 255 255);
  font-size: 16px;
}
</style>
